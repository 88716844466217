module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-164509271-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"anoriqq's Blog","short_name":"anoriqq","start_url":"/","background_color":"#ffffff","theme_color":"#4ff9ff","display":"minimal-ui","icon":"content/assets/anoriqq-icon.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"897dc7abe19cc42b831a745c4a64d44d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-styled-components-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"dark":{"palette":{"darkBase":"0,0,0","darkMain":"255,255,255","darkAccent":"0, 122, 204","lightBase":"255,255,255","lightMain":"0,0,0","lightAccent":"0, 122, 204"},"global":{"bg":"0,0,0","color":"255,255,255","link":"0, 122, 204"}},"light":{"palette":{"darkBase":"0,0,0","darkMain":"255,255,255","darkAccent":"0, 122, 204","lightBase":"255,255,255","lightMain":"0,0,0","lightAccent":"0, 122, 204"},"global":{"bg":"255,255,255","color":"0,0,0","link":"0, 122, 204"}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
